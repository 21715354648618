.zona_usuario {
  margin-top: 150px;
  flex: 1;
}

.zona_usuario h2 {
  text-align: center;
  font-size: 3rem;
}

.zona_usuario nav {
  text-align: center;
  margin-top: 25px;
  background-color: #428bca;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.zona_usuario a {
  color: white;
  margin: 20px;
}

.imagen_avatar,
.editar_usuario,
.borrar_usuario {
  margin-top: 50px;
}

.imagen_avatar h2,
.editar_usuario h2,
.borrar_avatar h2,
.borrar_usuario h2 {
  font-size: 2rem;
}

.form_nuevo {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.form_nuevo label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form_nuevo input {
  border: 1px solid #428bca;
  padding: 0.6rem;
}

.form_nuevo button {
  border: 1px solid white;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  background-color: #428bca;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.borrar_avatar {
  text-align: center;
  margin-top: 50px;
}

.borrar_usuario {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.borrar_usuario button {
  border: 1px solid white;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  background-color: #428bca;
  color: white;
  font-size: 1.5rem;
  width: 20%;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .zona_usuario nav {
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .borrar_usuario button {
    font-size: 0.7rem;
  }
}


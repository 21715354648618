.zona_admin {
  margin-top: 150px;
  flex: 1;
}

.zona_admin h2 {
  text-align: center;
  font-size: 3rem;
  margin: 0 20px;
}

.zona_admin nav {
  text-align: center;
  margin-top: 25px;
  background-color: #428bca;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.zona_admin a {
  color: white;
  margin: 20px;
}

.nuevo_problema {
  margin-top: 50px;
}

.nuevo_problema h2 {
  font-size: 2rem;
}

.form_nuevo {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.form_nuevo label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form_nuevo input {
  border: 1px solid #428bca;
  padding: 0.6rem;
}

.form_nuevo textarea {
  border: 1px solid #428bca;
  max-width: 80vw;
}

.form_nuevo button {
  border: 1px solid white;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  background-color: #428bca;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.imagenes {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}

.imagenes_button {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.imagenes_button button {
  border: 1px solid #428bca;
  border-radius: 25px;
  padding: 2px 10px 2px 10px;
  cursor: pointer;
  background-color: white;
  width: 50%;
}

.borrar_avatar div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.borrar_avatar img {
  margin-top: 25px;
}

.borrar_avatar button,
.borrar_problema button {
  border: 1px solid #428bca;
  border-radius: 25px;
  padding: 2px 10px 2px 10px;
  cursor: pointer;
  background-color: white;
}

.borrar_problema p {
  text-align: center;
  padding-top: 10px;
}

.sin_acceso {
  flex: 1;
  margin-top: 200px;
  color: red;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 1200px) {
  .imagenes {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .zona_admin nav {
    flex-wrap: wrap;
  }

  .zona_admin {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .zona_admin {
    margin-top: 100px;
  }

  .zona_admin h2 {
    font-size: 2rem;
  }

  .imagenes img {
    width: 320px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 1200px;
  margin: 0 auto;
}

main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

section.problema {
  flex: 1;
}

a {
  text-decoration: none;
  color: #428bca;
}

a.active {
  font-weight: bold;
}

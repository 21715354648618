.seccion_inicio {
  margin-top: 150px;
  flex: 1;
}

.seccion_inicio h2 {
  text-align: center;
  font-size: 3rem;
}

.seccion_inicio hr {
  height: 10px;
  border: 1;
  box-shadow: inset 0 9px 9px -3px rgba(11, 99, 184, 0.8);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-top: 50px;
}

.form_inicio {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.form_inicio label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form_inicio input {
  border: 1px solid #428bca;
  padding: 0.6rem;
}

.form_inicio p {
  line-height: 2rem;
  text-align: center;
}

.form_inicio button {
  border: 1px solid white;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  background-color: #428bca;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.inicioRegistro {
  border: 1px solid #428bca;
  border-radius: 25px;
  padding: 2px 10px 2px 10px;
  margin-left: 10px;
}

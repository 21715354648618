.pie_pagina {
  margin-top: 20px;
}

.pie_pagina section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.pie_pagina p {
  color: #428bca;
}

.pie_pagina hr {
  height: 10px;
  border: 1;
  box-shadow: inset 0 9px 9px -3px rgba(11, 99, 184, 0.8);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.seccion_registro {
  margin-top: 150px;
  flex: 1;
}

.seccion_registro h2 {
  text-align: center;
  font-size: 3rem;
}

.form_registro {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.form_registro label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form_registro input {
  border: 1px solid #428bca;
  padding: 0.6rem;
}

.form_registro p {
  margin: 30px;
  text-align: center;
}

.form_registro button {
  border: 1px solid white;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  background-color: #428bca;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.iniciaSesion {
  border: 1px solid #428bca;
  border-radius: 25px;
  padding: 2px 10px 2px 10px;
  margin-left: 10px;
}
